import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage)
  },
  {
    path: 'legal',
    loadComponent: () => import('./pages/legal/legal.page').then((m) => m.LegalPage)
  }
];
