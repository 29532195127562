import {ApplicationConfig, isDevMode} from '@angular/core';
import {provideRouter, withInMemoryScrolling} from '@angular/router';
import {routes} from './app.routes';
import {provideServiceWorker} from '@angular/service-worker';
import {provideHttpClient} from '@angular/common/http';
import * as DOMPurify from 'dompurify';
import {trustedTypes} from 'trusted-types';
import {trustedResourceUrl} from 'safevalues';

const myPolicy = trustedTypes.createPolicy('custom', {
  createScriptURL(dirty) {
    return dirty;
  },
})

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withInMemoryScrolling({
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    })),
    // @ts-ignore
    provideServiceWorker(trustedResourceUrl`/ngsw-worker.js`, {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideHttpClient(),
  ],
};
