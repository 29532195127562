import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterLink, RouterOutlet} from '@angular/router';
import {Alignment, Fit, Layout, Rive, RuntimeLoader} from '@rive-app/canvas';

RuntimeLoader.setWasmUrl('/assets/rive.wasm');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink],
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('logo') logo!: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit() {
    const logo = new Rive({
      src: "/assets/elite_fuite_logo_header_2.riv",
      canvas: this.logo.nativeElement,
      autoplay: true,
      layout: new Layout({fit: Fit.Contain, alignment: Alignment.Center}),
      onLoad: () => {
        logo.resizeDrawingSurfaceToCanvas();
      },
    });
  }
}
