<header>
  <div>
    <canvas class="logo" #logo width="170" height="170"></canvas>
    <ul class="desktop">
      <li><a [routerLink]="'/'" fragment="operational">Notre approche</a></li>
      <li><a [routerLink]="'/'" fragment="professionals">A destination des professionnels</a></li>
      <li><a [routerLink]="'/'" fragment="location">Zone d'intervention</a></li>
      <li><a [routerLink]="'/'" fragment="contact">Nous contacter</a></li>
    </ul>
    <button class="mobile" aria-label="Menu"></button>
    <div style="">
      <ul>
        <li><a [routerLink]="'/'" fragment="operational">Notre approche</a></li>
        <li><a [routerLink]="'/'" fragment="professionals">A destination des professionnels</a></li>
        <li><a [routerLink]="'/'" fragment="location">Zone d'intervention</a></li>
        <li><a [routerLink]="'/'" fragment="contact">Nous contacter</a></li>
      </ul>
      <div class="social">
        <p>NOUS SUIVRE</p>
        <img src="assets/facebook.svg" alt="F" />
      </div>
    </div>
  </div>
</header>

<router-outlet></router-outlet>

<footer>
  <div class="limiter">
    <div class="logo">
      <img src="assets/logo.svg" alt="Elite-Fuite" />
    </div>
    <div class="links">
      <ul>
        <li><a [routerLink]="'/'" fragment="operational">Notre approche opérationnelle</a></li>
        <li><a [routerLink]="'/'" fragment="professionals">A destination des professionnels</a></li>
        <li><a [routerLink]="'/'" fragment="location">Zone d'intervention</a></li>
        <li><a [routerLink]="'/'" fragment="contact">Nous contacter</a></li>
      </ul>
      <div>
        <a [routerLink]="'/legal'">Mentions Légales</a>
        <p>|</p>
        <a href="/">Tous droits réservés Elite Fuite 2023</a>
      </div>
    </div>
<!--    <div class="social">-->
<!--      <p>NOUS SUIVRE</p>-->
<!--      <div>-->
<!--        <img src="assets/facebook.svg" />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</footer>

